import React from "react";
import img1 from "./blogo.png"


const Blogo = () => {
    return (
        <img className="b--logo" src={img1} alt="pic"/>
    )
}

export default Blogo;